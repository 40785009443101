
.check-input:checked {
    right: 0;
    border-color: #7a9cf1;
  }
  .check-input:checked + .check-label {
    /* background-color: #68D391; */
    /* #2260FF */
  }

  .h-px{height:25px;}
  .w-px{width:25px;}