@tailwind base;
@tailwind components;
@tailwind utilities;



body{
    background-image:none;
    /* background-color: #DFDBE5; */
    /* background-color: #F4F4F4;  */

    background-color: #f5fbff; 

    /* background-image: url(../../Assets/Images/background/arc_background.svg);
    background-repeat: repeat;
    background-size: 2%; */
    
    /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%239C92AC' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E"); */

    /* --moz-transform: scale(0.9,0.9);
    zoom:0.9;
    zoom: 90%; */

}
html{
    overflow:auto;
}


.loginpage{
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    /* from heropatterns.com */
    background-color: #fafafd;
    background-image: url("../Assets/Images/background/arc_background.svg");
} 

.companytitle{

    font-weight: 900;
    color:#2260FF;
}


#Landing{
    /* padding-left : 90px; */
    /* padding-right : 25px; */
}
#root>#AppNavBar{
    display:none;
    /* position: sticky;
    top:200px; */
}

/* Phone Portrait */
@media (max-width: 820px) {
    #Landing{
        padding:0;
    }
    #root>#AppNavBar{
        display:block;
    }
}
