@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@1&family=Source+Sans+Pro:wght@600&display=swap');
*{
    box-sizing: border-box;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    user-select:none; /* to not highlight when clicked */
}
button{
    padding: 0;
    background: transparent;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
}
button:active{transform: translateY(1px); }
:root{
    --vh: 1vh;
    --background-color: #FAFAFD;
    --blue-color-hsla : hsla(223, 100%, 57%, 1);
    --blue-color-hex : #2260FF;
}
input,textarea{
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}
html{
    position: relative;
    font-size:100%; 
    top: 0;
    left: 0;
    height: 100%;
    /* height: calc(var(--vh) * 100); */
    width: 100%;
    margin: 0; padding: 0;


    
}
body{
    position: relative;
    /*background:rgb(255, 255, 255);*/
    /* background-image: url('./Assets/Images/background/joe-woods-4Zaq5xY5M_c-unsplash.jpg'); */
    /* background: #FAFAFD; */
    /* background-size: cover; */
    height: 100%;
    width: 100%;
    margin: 0; padding: 0;

}
#root{
  position: relative;
  height: 100%;
  width: 100%;
}
.app{
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0; padding: 0;
    display: flex;
    overflow-x: hidden;
    
    /* from heropatterns.com */

    background-color: #fafafd;
    background-image: url(./Assets/Images/background/arc_background.svg); 
}


/********************** LOADING SCREEN **********************/
@font-face {
    font-family: Bellagium;
    src: url(./Assets/Fonts/Montserrat-Bold.ttf);
  }

.loadingContainer{
    position: absolute; height: 100vh; width: 100%;
    top: 0; left: 0;
    overflow:hidden;
    display: none; justify-content: center; align-items:center;
    z-index:100;
}
.loadingContainer> .loadingScreen{
    background: var(--blue-color-hex );
    background-image: url(./Assets/Images/logos/logo_size_wallpaper_invert.jpg);
    background-size: contain;
    background-position:center;
    background-repeat: no-repeat;
    opacity: 1;
    position: absolute; z-index: 1000;
    top:0;
    width: 100%; height: 100vh;
}

.loadingContainer>.loadingtxt{color: white;z-index: 1001;opacity: 0; font-size: 5em; font-family:"Bellagium" }



/********************** NAVBAR **********************/

#sidebar{
    background-image: url(./Assets/Images/background/topography.svg);
    background-blend-mode:hue;
}

/*
 #AppNavBar{
    display: block;
    position: fixed; top: 0; left: 0;
    height: 100%;
    width: 100px;
    min-height: 800px;
    background: #2260FF;
    background-image: url(./Assets/Images/background/topography.svg);
    display: grid;
    grid-template: 30% 50% 10% 10% / repeat(1, 1fr);
}


#sloth-logo{
    position: relative;
    grid-area: 1 / 1 / 2 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto; width: auto; 
}
#sloth-logo>img{width: 80px;  cursor: pointer;}

#menu{
    position: relative;
    grid-area: 2 / 1 / 3 / 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.selectedItemInAppNavBar{
    background-color: #fafafd;
    background-image: url("./Assets/Images/background/arc_background.svg");
    transform: translateX(10%); 
}

#menu > .outercircle:hover{
    background-color: #fafafd;
    background-image: url(./Assets/Images/background/arc_background.svg);
    transform: translateX(10%); 
}
#menu > .outercircle:active{transform: translate(10px, 5px); }
#menu > .outercircle{
    position: relative;
    width: 110px;
    height: 100px;
    border-radius: 50%;
    transition: transform .3s;
    
    display: flex; justify-content: center;align-items: center;
}
#menu > .outercircle>.innercircle{
    position: relative;
    width: 80px; height:80px; 
    display: flex; justify-content: center; align-items: center;  
    border-radius: 50%; background-color: #2260ff;
    background-image: url(./Assets/Images/background/topography.svg);
}
#menu > .outercircle>.innercircle> img{width: 50%; } 


#menu5{
    position: relative;
    grid-area: 4 / 1 / 5 / 1;
    display: flex;
    justify-content: center;
}
#menu5 > img{width: 50%}
#menu5 > img:hover{width: 70%;} 
 */

/********************** APP - Activity Screen **********************/

.AppGrid, .MarketplaceGrid, .AssessGrid{
    /* background: var(--background-color); */
    position: relative; top: 0; left: 0;
    height: 100%;
    width: calc(100% - 80px) ;
    min-height: 800px;
    /* max-height: 900px; */

}   

.AssessGrid{width:100%;}

.AppGrid, .MarketplaceGrid{
    left: 4rem;
}

.AppGrid{
    
    display: grid;
    grid-template: 20% 80% / repeat(1, 1fr) 300px;
}

.MarketplaceGrid{
    padding-left: 5%;
    padding-right: 5%;
}

#SwitchTimerRoomsBtn{
    display:none;
}

/********** topleft - Quote ***********/
.AppQuote{
    position: relative;
    grid-area: 1 / 1 / 2 / 2;
    padding-left: 3%;
    height:100%;width: 100%;
    display:flex;justify-content: center;align-items: center;
}
/* .quoteimg{
    position: relative;
    height: 110%;
    max-width: 300px;
    padding-left: 2%;
    padding-right: 2%;
    margin: auto;
} 
.quotetxt{
    position: relative;
    height: 90%;
    width: 100%;
    border-radius: 20px;
    display: grid;
    grid-template: 100% / 20% 60% 20%;

    font-style:normal;
    font-weight: 100;
    font-size: small;
    line-height: 2em;
    text-align: center;

    background: #F5F8FE;
}

.AppQuote > .quotetxt > .right{grid-area: 1/3/2/4; 
                                display: flex; flex-direction: column;justify-content: space-around;align-items: center;}
.AppQuote > .quotetxt > .center{grid-area: 1/2/2/3; background: rgb(233, 235, 255) ;}
.AppQuote > .quotetxt >  .left{grid-area: 1/1/2/2;display:flex; flex-direction:column;}
.AppQuote > .quotetxt >  .left > .top-left{display:flex; justify-content:space-between; }
.AppQuote > .quotetxt >  .left > .bottom-left{height: 100%; display:flex; flex-direction: column; justify-content:flex-end; align-items:center;}
.AppQuote > .quotetxt >  .left > .bottom-left > .AdminPic{display:flex;  justify-content:center; align-items:center;}
*/


.AppTopBar{
    position: relative;
    grid-area: 1 / 1 / 2 / 2;
    border-radius:20px;
    margin: 3%;
    height:90%;width: 97%;
    display:flex;justify-content: flex-start;align-items: center;
    background: #F5F8FE;
    /* border: 1px solid rgba(169, 169, 169, 0.25); */
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.AppTopBar>.img{height:20%; margin-left: 3%; margin-right:3%;}
.AppTopBar>.txt{color:black; font-size: calc(0.25rem + 0.45vw) ; letter-spacing:.5px ;}






/********** topright - Options ***********/
.AppOptions{
    position: absolute;
    right: 0;
    /* top: 10px; */ 
    margin-top: 2%;
    width: 350px;
    /* grid-area : 1 / 2 / 2 / 2; */
    /* width: 100%; */
    z-index:10; 
}
.AppOptions > .top{
    position: relative;
    height: 30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    /* justify-content: space-around; */
    justify-content: flex-end;
    padding-left: 10%;
    padding-right: 10%;
}
/* .people, .notification{
    cursor: pointer;
    position: relative;
    height: 40px; width: 40px; 
    background: white; border-radius: 50%;
}
.people>img, .notification>img{transform: translate(8px, 8px);}
.people::after, .notification::after{
    position: absolute; transform: translate(25px, -30px);
    height: 20px; width: 20px; border-radius: 50%;
    display: flex; justify-content: center; align-items: center;
    content:"2"; font-weight: 600;
    color: white; background: red;
}
.username{padding-bottom:8px; font-size:calc(0.7rem + 0.5vw);}
.username::after{content:url(./Assets/Images/options/chevron-down.svg); margin-left: 2px; position: absolute;height: 20px; width: 20px; }

.subpanel{
    display:none;
    position: absolute;
    cursor:auto;
    z-index: 1;
    
    background:rgba(255, 255, 255, 0.62);
}
.notification:hover > .subpanel,.people:hover > .subpanel{ display:block;text-align: left; min-width: 150px;transform: translate(-50px, 10px)}
.username:hover > .subpanel{display:block;text-align: center;min-width: 100px;}

.optionbtn{ display: block ; height: 25%;padding: 5% 20% 5%; border: 1px solid white; font-size: 0.8em;}
.notificationbtn, .peoplebtn{ display: block ; text-align: left; height: 25%;padding: 5% 5% 5%; border: 1px solid white; font-size: 0.8em;}




.AppOptions > .bottom{
    height: 70%;
    width: 100%;
    display: flex;
    align-items:flex-end;
    justify-content: center;
}
.sound{
    background: white;
    height: 80%;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}
.sound > .title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sound > .title > .txt {
    color: #B3C8FC;
    font-weight: 600;
    font-size: 1rem;
    margin-left: 3px;
}

.sound > .audio {height: 50px; width: 120px; display: flex; justify-content:space-between; align-items:center;}
.sound > .audio > a{height: 30px ; width: 30px;opacity:0.6;} */

/********** bottomleft - Camgrid ***********/
.AppCentralPanel{
    grid-area: 2 / 1 / 2 / 2;
    padding: 3%;
    display: none;
    padding-right: 0;
    height: 100%;
    min-height: 650px;
}
/** CamMenu  */
#cammenugrid, .camgridstudywithmelive{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85%;
    width: 100%;
    /* background: #c6d6ffbc; */
}
.camgridstudywithmelive{background: #c6d6ffbc;border-radius: 20px;}
#cammenu {
    /* background: #F5F8FE; */
    height: 100%;width: 100%;border-radius: 20px;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    display: grid; 
    grid-template : repeat(2,1fr) / 45% 55% 0%;
}
#cammenu > .Image {background-size: cover;opacity:0.7;background-repeat: no-repeat;}
#cammenu > .first-column-top-row{grid-area: 1/1/2/2; background-size: 40%;background-position:bottom;}
#cammenu > .first-column-bottom-row{grid-area: 2/1/3/2; background-position:bottom;background-size: 40%;}
#cammenu > .second-column{grid-area: 1/2/3/3;  background-position:bottom;background-size: 90%;}
#cammenu > .third-column-top-row{grid-area: 1/3/2/4; background: rgb(181, 181, 181);opacity:0.7;}
#cammenu > .third-column-bottom-row{grid-area: 2/3/3/4; background: rgb(181, 181, 181);opacity:0.7;}
#cammenu > * {position: relative; padding: 5%;display: flex; justify-content:space-between ;margin: 2px;border-radius: 10px;background:hsla(223, 100%, 57%, 0.2);}
#cammenu > * > .subtitle{z-index:1; font-size:calc(1.5rem + 0.75vw); color: rgb(255, 255, 255);}
#cammenu > * > .choiceslist{font-size:calc(0.7rem + 0.5vw); display: flex; flex-direction: column; justify-content:flex-start; align-items: center;}
#cammenu > .CameraSettings> .choiceslist, #cammenu > .GeneralSettings > .choiceslist{position: absolute; bottom: 0;}
#cammenu > * > .choiceslist > .choice {
    height: 25px;
    width: 100px;
    border-radius: 20px;
    margin: 3% 15% 10% 3%;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    background: white; color: blue;
}
/* #cammenu > .title{font-size:xx-large} */
/* #cammenu > .menu{background: black;width: 100%; height: 100%;  display: flex; justify-content: space-around; align-items: center;} */
#cammenu > .menu > * {display: flex; flex-direction: column; justify-content: space-around; align-items: center;}




/** End of CamMenu */

/* #camgrid {
    background: #c6d6ffbc;
    height: 70%;
    width: 100%;
    padding: 1%;
    border-radius: 20px;
    display: grid;
    grid-template: repeat(2, 1fr) / repeat(3, 1fr);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
} */

#camgridliveroom, .camgridbrowse {
    position: relative;
    background: #c6d6ffbc;
    height: 75%;
    width: 100%;
    padding: 1%;
    border-radius: 20px;
    display: grid;
    /* grid-template: repeat(2, 1fr) / repeat(3, 1fr); */
    overflow-y:scroll ;
    /* filter: drop-shadow(0px 4px 4px rgba(73, 60, 60, 0.25)); */
}
#camgridliveroom{
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 200px;
}
.camgridbrowse{
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 33%;
}
#camgridliveroom > .video,#camgridliveroom > video, #camgridstudywithme > .video, #camgridstudywithme > video {
    border: 1px solid beige;
    margin: 3%;
    border-radius: 20px;
    background: hsla(0, 0%, 77%, 1); /*rgba(28, 186, 181, 0.386);*/
    height: 94%;
    width: 94%;
    overflow: hidden;
    object-fit: cover;
}
#cambuttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
#cambuttons > .ShareButton, .BackButton{
    height: 50px;
    width: 150px;
    border-radius: 20px;
    margin: 3% 0% 3% 3%;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}
#cambuttons > .BackButton{color: white; background: hsla(223, 100%, 57%, 1);}
#cambuttons > .ShareButton{background: white; color: hsla(223, 100%, 57%, 1);}


.AppCandidates{
    /* max-width:calc(100vw - 300px - 4rem) */
}

/********** Modals ***********/
.Modal{
    max-height: 80vh;
    overflow: scroll;
    background: black;
}

/********** bottomright - Activitypanel ***********/
.AppJPPanel{
    position:absolute;
    top: 100px; right: 0; bottom: 0%;
    /* height: 90%; */
    width: 300px;
    /* max-width: 30vw; */
    height: 600px;
    /* max-height: 100vh; */
    /* 
    position relative
    grid-area: 2/2/2/2;
    height: 100%;
    min-height: 650px;
    width: 100%; */
    padding-right: 5%;
    display: none;
    justify-content: center;
    align-items: center;
}
#activitypanel{
    width: 100%;
    height: 85%;
    border-radius: 50%;
    position: absolute;
    right: -50%;

    background: var(--blue-color-hex);
    background-color: #2260ff;
    background-image: url(./Assets/Images/background/topography.svg);
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */

    display: flex;
    flex-direction: column;
    justify-content:space-around;
    align-items: center;

    /* overflow-y: scroll; */
}
/*****/
.activitiestitle{
    color: #808080;
    font-size:calc(0.5rem + 0.4vw);;
    position: absolute; top:0; text-align: center; left: 0%; 
}
/*****/
.activities{
    display: grid;
    grid-template : repeat(3, 1fr) / repeat(2, 1fr);
    padding: 2%;
    position: relative; height:100%;width: 100%;
}
.activityWithBtn{position: absolute; height: 15%;width: 60%;transition: transform .3s;  }
.activity{
    position: relative;
    height: 100%;
    width: 70%; margin: 0;
    background: white;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;

    cursor: pointer;

    transition: transform .3s ease-in-out;
}

#activity0{transform: translate(-20%, 40%);}
#activity1{transform: translate(-40%, 155%);}
#activity2{transform: translate(-60%, 270%);}
#activity3{transform: translate(-40%, 385%);}
#activity4{transform: translate(-20%, 500%);}

.emptyactivity{display: flex; align-items: center; justify-content: center;background: #5786ffbc; color: white;}
.emptyactivity>.txt{text-align: center; font-size:small}




.activity>input{visibility: hidden;position: absolute;width: 100%;}
.activity>input + div {width: 100%; position: relative;border:2px solid transparent;}
/* .activity>input:checked {border:2px solid rgba(28, 186, 181, 0.386);background:rgba(28, 186, 181, 0.3);border-top-left-radius: 20px; border-top-right-radius: 20px;} */
/* GOT RID OF THE +div in the checked + div so there is no color green on the activity */

.activity > .title{
    display: flex;
    padding-left: 7%;
    padding-top: 5%;
    font-size:x-small;
}
.activity > .title > .img {
    height: 15px;
    width: 15px;
    border-radius: 5px;
    background: #2260FF;
    margin-right: 5%;

    display: flex;
    justify-content: center;
    align-items: center;
}
.activity > .title > .txt {
}

.activity > .timespent{
    height: 35%;
    width: 85%;
    background: #2260FF;
    color: white;
    border-radius: 10px;
    font-size:x-large;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10% 0 5%;
}
.activity > .timetoachieve {
    color: #2260FF;
    width: 85%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20% 0 5%;
}

.activity > .timetoachieve{font-size: small;}
.activity > .timespent > .txt::after{content:"p"; font-size:small;position: relative;transform: translateY(5px);}
.activity > .timetoachieve > .txt::after{content:"p"; font-size:xx-small;}





    

.activitymodification{
    position: absolute;
    top: 5%; right : 0; width: 30%;
    justify-content: center;
    align-items: center; 
    display: flex;
    flex-direction: column;
}
.renameactivity, .modifygoal{
    font-size:x-small;
    height: 30px;
    width: 120px;
    border-radius: 20px;
    margin: 3% 0% 3% 3%;
    color: white; 
    background: hsla(223, 100%, 57%, 0.6);
}
.activitymodification>button{position: relative; width: 80%; height: 50%;display:flex;background: transparent;}
.activitymodification>button>img{height: 30%; width: 30%;}

/*****/
.timer{
    position: absolute;
    background: white;
    height: 130px;
    width: 180px;
    border-radius: 20px;
    margin: 15px 0 15px 0;
    transform: translate(-150px, 25%);
    display:none;
}

.timer > .img{
    height: 20px;
    width: 20px;
    border-radius: 5px;
    /* background: #2260FF; */
    margin: 5px;
    margin-right: 5%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.timer > .txt{
    position: relative;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: flex-start;
    height: 40%;
    margin: 5px 5px 5px 5px;
    font-size:small;
}

#timermodebtn{
    position: absolute;
    
    width: 70%;
    top:-1px;
    right: 0;
    border-radius: 20px;
    background: rgb(255, 101, 101);
    color: white;
    padding-left: 4px;
    text-align:left;
    font-size:x-small;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
}

#minutes, #seconds{
    color: #2260FF;
    font-size:3.5rem;
    letter-spacing: 2px;
    font-weight: 700;
}
#minutes::after{content:":"}
.moreless{display:flex; flex-direction: column;}
.moretime, .lesstime{
    height: 20px;
    width: 20px;
    padding: 2px;
    border: 2px solid #2260FF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#2260FF;
}

.triggertime{
    /* width: 100%;
    display: flex;
    justify-content: center; */

    height: 30px;
    width: 150px;
    border-radius: 20px;
    margin: 3% 3% 3% 3%;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    background: white; color: gray;
    font-size:small;
}
.triggertime > img{
    height: 3rem; 
    width: 3rem;
}
    
#timerreturnbtn{
    position: relative;
    bottom : 18%;
    left: -170px;
    color:var(--background-color);
    background:var(--blue-color-hex);
    height: 50px;
    width: 150px;
    border-radius: 20px;
    margin: 3% 0% 3% 3%;
    /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
    display: none;
    font-size:small;
}


/********************** RESPONSIVENESS OF THE WEBSITE **********************/

/* Phone Landscape */
/* @media screen and (max-width: 1060px) {
    #camgrid { left: 0%; width:67%; }
    #activitypanel { left: 0%; width:100%; }  
} */

.AppearingPanel{display: block}
.DisappearingPanel{display: block}

@media (max-width: 1120px) {
    #cammenu{display: flex;flex-direction: column;justify-content: center; }
    #cammenu>.Image{display: none;}
    .AppearingPanel{display: block}
    .DisappearingPanel{display: block}

    .AppGrid{grid-template: 20% 80% / repeat(1, 1fr);}
    .AppJPPanel{display: none;visibility:hidden;}

}

/* Phone Portrait */
@media (max-width: 820px) {
    
    html{
        overflow:scroll;
    }

    #AppNavBar{position: absolute; background: transparent; left: 0; width: 100px; height: 100px; min-height: 100px;}
    #AppNavBar>#menu, #AppNavBar>#menu5{display: none; }
    #AppNavBar>#sloth-logo{position: absolute; top:0; left:10px; background-color:#2260FF; border-radius: 50%;}
    
    /* body::before{content:"App's Mobile version not available yet"} */
    .app>*{display:none; visibility:hidden;}
    .app{background-color: white; background-image: url(./Assets/Images/logos/logo_not_available_for_mobile.png); background-size: contain; background-repeat: no-repeat;}
    /* .app{ background-image: url("./Assets/Images/background/arc_background.svg");position: absolute; display: flex; justify-content: center; align-items:flex-start; padding: 10px 10px 0%; left: 0; top: 0; height: 100%; width: 100%;} */
    .app > .AppGrid{ padding: 0; background: transparent; position: relative;display: flex;flex-direction: column; align-items: flex-end;  left: 0; top: 10px; height: 100%; width: 100%; }
    .AppGrid::before{ content:"Cameras not available yet on Mobile & Small screens"; color: rgb(57, 55, 55); font-size: calc(0.5rem + 20%) ; }  
    .MarketplaceGrid{left:0; padding:0;}
    
    .AppOptions {position: relative; margin: 0; padding: 0;width: 75%; max-width: 301px; background-color:#2260FF; border-radius: 200px;}
    .AppOptions > .top {height: 100%;} 
    .people, .notification{margin-right: 5%;}
    .username{color: white; }.username>*{color:black;}
    .username::after{content:url(./Assets/Images/options/chevron-down-white.svg);}
    .AppOptions > .bottom{display: none;}
     
    #SwitchTimerRoomsBtn{
        display:block;
        height: 24px;
        width: 75%; max-width: 301px;
        border-radius: 20px;
        margin: 3% 0% 3% 3%;
        /* filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)); */
        background: white; color: hsla(223, 100%, 57%, 1);
        font-size: calc(0.45rem + 1.45vw);
        font-weight: 500;
    }
    .AppearingPanel{display: block}
    .DisappearingPanel{display: none}

    .AppQuote{width: 100%;}
    .AppCentralPanel{height: 100%; width:100%;}

    .AppTopBar {height: 170px; margin: 0; padding: 0; margin-top : 25px; margin-bottom : 25px;}
    #cammenu{display: flex;flex-direction: column; justify-content: flex-start;}
    #cammenu>.Image{display: none;}
    
    .AppJPPanel{position: relative; margin: 0; padding: 0;height: auto; width: 100%; max-width: 500px;}
    .moretime, .lesstime{height: 30px; width: 30px;}
    
    
}  

